import React from 'react'
import marketingTeam from '../../assets/images/marketing-team.jpg'
import ourTeam from '../../assets/images/our-team.jpg'

function AboutUs() {
    return (
        <>
            <div className={'about-us-container'} id={'about-us'}>
                <div className={'container'} id={'our-story'}>
                    <div className={'column-rows'}>
                        <div className={'content right-padding'}>
                            <h3>{'About us'}</h3>
                            <h2>{'Our journey'}</h2>
                            <p>
                                {
                                    'When our team of digital marketing and IT professionals came together our first major project was JustFlyEmpty. This platform for private aviation services connected us to many successful entrepreneurs and soon we were not only facilitating private jet bookings, we were also giving clients the VIP treatment when it came to their own digital marketing.'
                                }
                            </p>
                            <p>
                                {
                                    'As Just Fly Solutions grew, we made sure to recruit experts from a variety of fields such as marketing, advertising, IT, software development and more. Today, we are a full-service boutique agency that partners with businesses to help them achieve the full potential of any project by providing services such as strategy development, concept, structure, planning, execution and monitoring.'
                                }
                            </p>
                        </div>
                        <div className={'content img-content'}>
                            <img
                                src={marketingTeam}
                                alt={'About us: Our journey '}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'about-us-container'}>
                <div className={'container'}>
                    <div className={'column-rows'}>
                        <div className={'content img-content'}>
                            <img
                                src={ourTeam}
                                alt={'About us: Our team'}
                            />
                        </div>
                        <div className={'content left-padding'}>
                            <h3>{'About us'}</h3>
                            <h2>{'Out team'}</h2>
                            <p>
                                {
                                    'We come from all corners of the globe and gather at one destination. A place that resonates with the latest digital trends and empowers clients to stand out from the crowd. Many of us came from big agencies and craved a more personalized, high-touch and highest-quality approach, which is exactly what you will experience here. Our clients are our partners and our relationships and results reflect that.  '
                                }
                            </p>
                            <p>
                                {
                                    'The JFS senior-level team includes marketing, advertising, IT and software development professionals with extensive experience. When you come on board, you will receive a dedicated team that collaborates with you for the entire journey. Our clients are not quick layovers, they are long-term relationships in the making because we are with you for the long haul.'
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs
