import React from 'react'

function Marketing() {
    return (
        <div className={'marketing-container'} id={'marketing'}>
            <div className={'container'}>
                <h3>{'Services'}</h3>
                <h2>{'Marketing'}</h2>
                <div className={'content-wrapper'}>
                    <div className={'content'}>
                        <h4>{'Strategic marketing'}</h4>
                        <p>
                            {
                                'Knowledge is power and we want to empower your projects with the best type of insight. We don’t rely on generic data and assumptions, instead, we offer personalized approaches to gathering information to serve your climb to the top.'
                            }
                        </p>
                        <p>
                            {
                                'Market research, Analysis and Customized Marketing Strategy JFS works with you to pinpoint your optimal audience, after which we create effective qualitative and quantitative research methodology and combine it with other resource research to gather intelligence. This data is then professionally analyzed and reported so that it can be shaped into a customized and optimized marketing strategy.'
                            }
                        </p>
                        <p>
                            <strong>
                                {'Lead Generation and Activation Campaigns'}
                            </strong>
                        </p>
                        <p>
                            {
                                'We provide campaign planning, execution & reporting while ensuring your funnel is filled with intelligent content and driving conversion.'
                            }
                        </p>
                    </div>
                    <div className={'content'}>
                        <h4>{'Marketing & communications'}</h4>
                        <p>
                            {
                                'When it comes to making your message heard – we are the wind beneath your wings. An integral part of any marketing campaign is communication through visual and written collateral.'
                            }
                        </p>
                        <p>
                            {
                                'Our team is here to assist you in conveying a consistent and effective message across all platforms by providing:'
                            }
                        </p>
                        <ul>
                            <li>
                                <strong>
                                    {'A Press Kit for each project:'}
                                </strong>
                                {
                                    'containing brand assets and materials to maximize your messaging and branding'
                                }
                            </li>
                            <li>
                                {
                                    'Public Relations Materials: Press Releases, media exposure support and PR-driven community management).'
                                }
                            </li>
                            <li>
                                {
                                    'Content Creation: Original solutions for marketing collateral such as banners, posts, brochures, videos, animations'
                                }
                            </li>
                            <li>
                                {
                                    'Copywriting Services: Engaging written content, keyword-rich SEO content, blogs, slogans and more.'
                                }
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={'content-wrapper'}>
                    <div className={'content'}>
                        <h4>{'Social media marketing'}</h4>
                        <p>
                            {
                                'For many businesses, the passport to profit is a strong social media presence.'
                            }
                        </p>
                        <p>
                            {
                                'We are here to help you take full advantage of the targeting, positioning and conversion potential of social media platforms with services such as:'
                            }
                        </p>
                        <ul>
                            <li>
                                {'Facebook, Instagram, LinkedIn and YouTube'}
                                {'management'}
                            </li>
                            <li>{'Business page creation'}</li>
                            <li>{'Strategic planning and execution'}</li>
                            <li>{'Content creation and management'}</li>
                            <li>{'Campaign and ads management'}</li>
                            <li>{'Community management'}</li>
                        </ul>
                    </div>
                    <div className={'content'}>
                        <h4>{'Performance marketing'}</h4>
                        <p>
                            {
                                'The success of our strategies is measurable and we take full advantage of that so that your campaigns can realize their full potential.'
                            }
                        </p>
                        <p>
                            {
                                'This is why we offer campaign management and performance marketing services such as:'
                            }
                        </p>
                        <ul>
                            <li>{'Search Engine Optimization'}</li>
                            <li>{'Google Ads campaign management '}</li>
                            <li>{'Facebook Ads campaign management '}</li>
                            <li>{'Email marketing'}</li>
                            <li>{'Client-specific workshops '}</li>
                            <li>{'Post-campaign analysis & reporting.'}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marketing
