import React from 'react'

import logo from '../../assets/images/jfs-logo-w@3x.png'
import menuItems from '../../utilities/menuItems'
// import MobileMenu from './_mobileMenu'

function Header() {
    return (
        <div className={'header-container'} id={'home'}>
            <div className={'menu'}>
                <a href={'/'} className={'logo'}>
                    <img src={logo} alt={'JustFlySolutions'} />
                </a>
                <div className={'right-menu'}>
                    {menuItems.map((item) => (
                        <a href={item.url} key={item.url}>
                            {item.title}
                            {'.'}
                        </a>
                    ))}
                </div>
                {/* <div className={'mobile-menu-link'}> */}
                {/*    <button> */}
                {/*        <img */}
                {/*            src={'assets/images/menu-icon.png'} */}
                {/*            alt={'mobile-menu'} */}
                {/*        /> */}
                {/*    </button> */}
                {/* </div> */}
                {/* <MobileMenu /> */}
            </div>
        </div>
    )
}

export default Header
