import React from 'react'

function PrimaryButton({
    title = '',
    onClick = () => null,
    noBorder = false,
    icon = null,
    id = ''
}) {
    return (
        <button
            className={`primary-button ${noBorder === true && 'no-border'}`}
            onClick={() => onClick && onClick()}
            id={id || title}
        >
            {icon && <img src={icon} alt={title} />}
            {title}
        </button>
    )
}

export default PrimaryButton
