import React from 'react'
import Marketing from '../molecules/marketing'
import Design from '../molecules/design'
import Development from '../molecules/development'
import servicesItems from '../../utilities/services-items'

function Services() {
    return (
        <div className={'services-container'} id={'what-we-do'}>
            <div className={'container'}>
                <h3>{'What we do'}</h3>
                <h2>{'Services'}</h2>
                <p className={'info'}>
                    {
                        'Just Fly Solutions is here to connect the dots – from idea to execution and beyond. Our solutions encompass the creative, the strategic, the innovative and even ingenious. Whether it’s building brands or building websites, we have the portfolio, personnel and passion to take you to the top.'
                    }
                </p>
                <p>
                    {
                        'Learn more about our marketing and development solutions by clicking on the destinations below.'
                    }
                </p>
            </div>
            <div className={'container'}>
                <div className={'summary-container'}>
                    {servicesItems.map(({ title, url, img: image }) => (
                        <div className={'service-item-link'} key={title}>
                            <a href={url}>
                                <img src={image} alt={title} />
                            </a>
                            <h4>{title}</h4>
                        </div>
                    ))}
                </div>
            </div>
            <Marketing />
            <Design />
            <Development />
        </div>
    )
}

export default Services
