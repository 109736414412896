import React from 'react'
import PrimaryButton from '../atoms/primaryButton'
import menuItems from '../../utilities/menuItems'
import chatIcon from '../../assets/images/chat-icon.png'

import logo from '../../assets/images/jfs-logo-w@3x.png'

function Footer() {
    return (
        <div className={'container footer-container'}>
            <h3>{'Ready to talk?'}</h3>
            <div className='phone-number'>
                <a href={'tel:+17862446287'}>📞 &nbsp; +1 786 244 6287</a>
                <a href={'mailto:info@justflysolutions.com'}>✉️ &nbsp; info@justflysolutions.com</a>
            </div>
            <a href={'tel:+17862446287'}>
                <PrimaryButton
                    title={"Let's talk now"}
                    noBorder
                    icon={chatIcon}
                    id={'contact-us'}
                />
            </a>
            <a href={'/#home'} className={'logo'}>
                <img
                    src={logo}
                    alt={'JustFlySolutions logo'}
                />
            </a>

            <div className={'footer-menu'}>
                {menuItems.map((item) => (
                    <a href={item.url} key={item.url}>
                        {item.title}
                        {'.'}
                    </a>
                ))}
            </div>

            <div className={'copy'}>
                <p>{'© 2020 JustFlySolutions. All rights reserved.'}</p>
            </div>
        </div>
    )
}

export default Footer
