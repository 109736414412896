import React from 'react'
import PrimaryButton from '../atoms/primaryButton'

function Hero() {
    return (
        <div className={'container'}>
            <div className={'hero-container'}>
                <div className={'hero'}>
                    <h1>{'Elevate your enterprise'}</h1>
                    <h2>{'Welcome Aboard!'}</h2>
                    <p>
                        {
                            'We are pleased to have you on our online hub. JFS is a boutique marketing agency that delivers strategies designed to skyrocket brands, first-class marketing services and a comprehensive portfolio of solutions that will take your business to the next level.'
                        }
                    </p>
                    <p>{'Are you ready for your profits to take off?'}</p>
                    <a href={'#contact-us'}>
                        <PrimaryButton
                            title={'Contact us'}
                            // onClick={() => window.open('#contact-us')}
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Hero
