import React from 'react'
import development from '../../assets/images/development.png'

function Development() {
    return (
        <div className={'development-container'} id={'development'}>
            <div className={'container'}>
                <h3>{'Services'}</h3>
                <h2>{'Development'}</h2>
                <div className={'content-wrapper'}>
                    <div className={'content'}>
                        <h4>{'Mobile app development'}</h4>
                        <p>
                            {
                                'Mobile apps are becoming an essential business tool as a response to an on-demand culture. Your customers are more likely to become engaged, connected, active and interactive if given the convenient option of an app.'
                            }
                        </p>
                        <p>
                            {
                                'We provide app design and development services for every stage of your mobile project.'
                            }
                        </p>
                        <p>
                            {
                                'Our team of agile thinkers and specialists will help you in the entire lifecycle of mobile app building – from initial planning, UI/UX design, App Development, Testing to final publishing of the App to the App Stores.'
                            }
                        </p>
                        <img
                            src={development}
                            alt={'Development services'}
                        />
                    </div>
                    <div className={'content'}>
                        <h4>{'Web development'}</h4>
                        <p>
                            {
                                'Web development is not a one-way ticket to a published page. It is the beginning of a productive partnership that should meet and often, anticipate, the needs of your customers and adapt web applications accordingly. This is why we offer a range of services and are constantly investing in our own knowledge of the latest web tools to provide comprehensive solutions.'
                            }
                        </p>
                        <p>
                            {
                                'Starting from UX, Design & Development of Web applications we are providing building simple and complex content management systems for various purposes, to support your needs. Some of them are:'
                            }
                        </p>
                        <ul>
                            <li>{'Blogs and forums'}</li>
                            <li>{'eShops'}</li>
                            <li>
                                {
                                    'Integration of popular platforms (like Shopify, WordPress, Magento etc.)'
                                }
                            </li>
                            <li>
                                {
                                    'Landing and pages for specialized services (eg. single page for mobile apps, mini-sites for products, campaigns, support and chatbot services etc.)'
                                }
                            </li>
                        </ul>
                        <p>
                            {
                                'No matter if you need custom solution from the scratch or need configuration of already existing services and third party solutions, our team of experts will help you to predict, visualize and build most efficient and high quality software which will improve your business.'
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Development
