import React from 'react'

function Development() {
    return (
        <div className={'design-container'} id={'design'}>
            <div className={'container'}>
                <h3>{'Services'}</h3>
                <h2>{'Design & Creative'}</h2>
                <div className={'content-wrapper'}>
                    <div className={'content'}>
                        <p>
                            {
                                'We are proud to have a phenomenal design team on board. They come from a range of industries and agency backgrounds and are experts in the nuances that dictate design direction for certain segments. There is a science to their art and JFS designers are truly specialists. Their imaginations have no limitations, but common services they can deliver include:'
                            }
                        </p>
                        <ul>
                            <li>
                                {'Web, UX/UI design & creative Logo design'}
                            </li>
                            <li>
                                {
                                    'Brand identity including colors, typography and design elements'
                                }
                            </li>
                            <li>
                                {'Guidelines for brand usage (web and print)'}
                            </li>
                            <li>{'Banner and ad design'}</li>
                            <li>{'Social media templates'}</li>
                            <li>{'Newsletter templates '}</li>
                            <li>{'Presentation templates '}</li>
                            <li>{'Brochure design '}</li>
                            <li>{'Merchandise design'}</li>
                        </ul>
                    </div>
                    <div />
                </div>
            </div>
        </div>
    )
}

export default Development
