import * as React from "react"
import '../assets/css/styles.css'
import Header from "../components/molecules/header";
import Hero from "../components/molecules/hero";
import AboutUs from "../components/organisms/aboutUs";
import Services from "../components/organisms/services";
import Footer from "../components/organisms/footer";

// markup
const IndexPage = () => (<div className={'app'}>
        <div className={'hero-wrapper'}>
            <Header/>
            <Hero/>
        </div>
        <AboutUs/>
        <Services/>
        <Footer/>
    </div>
)

export default IndexPage
