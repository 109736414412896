import marketingIcon from '../assets/images/marketing-icon.png'
import designIcon from '../assets/images/design-icon.png'
import devIcon from '../assets/images/dev-icon.png'

const servicesItems = [
    {
        url: '#marketing',
        img: marketingIcon,
        title: 'Marketing'
    },
    {
        url: '#design',
        img: designIcon,
        title: 'Design & Creative'
    },
    {
        url: '#development',
        img: devIcon,
        title: 'Development'
    }
]

export default servicesItems
